import React from 'react'
import { NavLink } from 'react-router-dom'

function NavigationButton({link, title, className}) {
    return (
        <NavLink
            to={"/booking/"}
            className={`bg-[#BBA085] px-8 py-1 rounded text-[#2C2C54] hover:text-[#BBA085] hover:bg-[#474787] transition-all ${className}`}
        >
            Book Now
        </NavLink>
    )
}

export default NavigationButton
