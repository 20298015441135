import React from 'react'
import { PiMoonStars } from "react-icons/pi";
import { NavLink } from 'react-router-dom'

function NavigationLogo({ className }) {
  return (
    <NavLink
      to={"/"}
      className={`${className} group flex flex-col items-center justify-center text-center transition-all`}
    >
      <PiMoonStars className="text-4xl mr-1" />
      <h1 className='group-hover:text-[#BBA085] text-xl'>Nostalgic Moments</h1>
    </NavLink>
  )
}

export default NavigationLogo
