import React from 'react'
import useScrollToHash from '../utilities/useScrollToHash';
import { Helmet } from 'react-helmet';

function Booking() {
  useScrollToHash();
  return (
    <div className="bg-[#2C2C54] text-[#bbbbbb] min-h-screen">
      <Helmet>
        <title>Book Your Event | Nostalgic Moments Calgary Event Planning</title>
        <meta name="description" content="Ready to make your special event unforgettable? Book your event planning with Nostalgic Moments and get a personalized quote for your celebration. Let us bring your vision to life." />
        <meta name="keywords" content="Calgary event booking, book event planning Calgary, custom event planning quotes, Calgary party planners, event planning services Calgary, proposal planning booking, birthday party booking Calgary, graduation planning quotes, luxury event planning Calgary, event consultation Calgary, special event booking, book event planner, Calgary event services, get a quote for event planning" />
      </Helmet>

    </div>
  )
}

export default Booking
