import React from 'react'
import Logo from '../Components/Navigation/NavigationLogo'
import { NavLink } from 'react-router-dom'

function Footer() {
  return (
    <footer className={`text-white relative bg-[#1f1f3b] text-center sm:text-left transition-all`}>
      <div className="section pt-14 pb-3 ">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-11 px-10">
          <div className="flex flex-col gap-y-2 items-center sm:items-start ">
            <Logo />
            <div className='text-gray-300'>
            <p>(403) 909-6130</p>
            <p>Nostalgic.calgary@gmail.com</p>
            <p>Calgary Alberta, Canada</p>
            </div>
          </div>
          <div className="space-y-4">
            <h1 className="text-xl font-semibold">Quick Links</h1>
            <ul className="">
              <li><NavLink to="/#about" className="footerLink">About</NavLink></li>
              <li><NavLink to="/#portfolio" className="footerLink">Portfolio</NavLink></li>
              <li><NavLink to="/#contact" className="footerLink">Content</NavLink></li>
            </ul>
          </div>
          <div className="space-y-4">
            <h1 className="text-xl font-semibold">Proposal Services</h1>
            <ul className="">
              <li><NavLink to="/Proposal/#whatWeDo" className="footerLink">What We Do</NavLink></li>
              <li><NavLink to="/Proposal/#packages" className="footerLink">Packages</NavLink></li>
              <li><NavLink to="/Proposal/#addOns" className="footerLink">Add Ons</NavLink></li>
              <li><NavLink to="/Proposal/#faq" className="footerLink">FAQ</NavLink></li>
            </ul>
          </div>
          <div className="space-y-4">
            <h1 className="text-xl font-semibold">Special Events</h1>
            <ul className="">
              <li><NavLink to="/Birthday/" className="footerLink">Birthday</NavLink></li>
              <li><NavLink to="/Anniversaries/" className="footerLink">Anniversaries</NavLink></li>
              <li><NavLink to="/Graduation/" className="footerLink">Graduation</NavLink></li>
              <li><NavLink to="/BabyShower/" className="footerLink">Baby Shower</NavLink></li>
              <li><NavLink to="/GenderReveals/" className="footerLink">Gender Reveals</NavLink></li>
              <li><NavLink to="/ThemedParties/" className="footerLink">Themed Parties</NavLink></li>
              <li><NavLink to="/Pre-WeddingEvents/" className="footerLink">Pre-Wedding Events</NavLink></li>
            </ul>
          </div>
        </div>
        <hr className="my-4 border-t border-gray-400" />
        <div className="text-center text-gray-400 text-sm">
          <p className='p-2'>Website developed and managed by Meet Suthar.</p>
          <p>&copy; {new Date().getFullYear()} Nostalgic Moments. All rights reserved.</p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
