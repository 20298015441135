import React from 'react';
import PageBanner from '../Sections/PageBanner';
import branch from "../media/branch.svg"
import useScrollToHash from '../utilities/useScrollToHash';
import { MdOutlineVideoCameraFront } from "react-icons/md";
import { MdOutlinePhotoCameraFront } from "react-icons/md";
import { IoSparklesOutline } from "react-icons/io5";
import { SiReasonstudios } from "react-icons/si";
import { PiChampagne } from "react-icons/pi";
import { FaCar } from "react-icons/fa";
import proposal2 from "../media/Proposal2.jpg"
import FAQSection from '../Sections/FAQSection';
import Contact from '../Sections/Contact';
import { BsFillBagPlusFill, BsPlus } from 'react-icons/bs';
import { Helmet } from "react-helmet";

function Proposal() {
    useScrollToHash();
    return (
        <div className="bg-[#2C2C54] text-[#bbbbbb]">
            <Helmet>
                <title>Nostalgic Moments | Calgary Proposal Planning</title>
                <meta name="description" content="Nostalgic Moments specializes in unforgettable Calgary proposal planning. From unique ideas to flawless execution, we help you create the perfect 'Yes' moment." />
                <meta name="keywords" content="Calgary proposal planning, engagement ideas Calgary, perfect proposals, romantic proposal planners, 
                proposal services Calgary, engagement event planning, surprise proposal ideas, proposal packages Calgary, luxury proposals, 
                marriage proposal organizers, creative proposal planning, custom proposal design Calgary, engagement planners, romantic event planning" />
            </Helmet>
            <PageBanner text={'Make Your Proposal Unforgettable'}
                backgroundImage={"https://storage.googleapis.com/bookfocal-production/images/c63a16b2fabd4d338b70826835f6d268.jpg"}
                links={[
                    { name: 'What We Do', link: '/Proposal/#whatWeDo' },
                    { name: 'Our Packages', link: '/Proposal/#packages' },
                    { name: 'AddOns', link: '/Proposal/#addOns' },
                    { name: 'FAQ', link: '/Proposal/#faq' },
                ]} />

            {/* What we do */}
            <div id="whatWeDo" className="bg-[#191930]">
                <div className='section text-center py-24 flex flex-col-reverse md:flex-row items-center md:text-left'>
                    {/* Image Section */}
                    <div className="md:w-2/3 px-6 mt-8 lg:mt-0 flex justify-center lg:justify-end">
                        <img
                            alt="celebration setup"
                            src={proposal2}
                            className="w-full saturate-50 max-w-xs sm:max-w-sm md:max-w-md lg:max-w-2xl rounded-lg shadow-lg object-cover"
                        />
                    </div>
                    {/* Text Section */}
                    <div className="md:w-2/3 py-16">
                        <h2 className="text-3xl sm:text-4xl lg:text-5xl leading-snug font-playfair text-[#bbbbbb]">
                            Ready to Pop the Question?
                        </h2>
                        <h2 className="text-3xl sm:text-4xl lg:text-4xl pb-6 lg:pb-12 leading-snug font-playfair text-[#BBA085]">
                            This is why you should choose us
                        </h2>
                        <p className="text-lg sm:text-xl lg:text-2xl leading-relaxed text-[#bbbbbb]">
                            We specialize in creating unforgettable proposals that reflect the unique love story between you and your partner. Whether you envision an intimate, quiet moment or a grand, extravagant gesture, we offer a range of thoughtfully curated packages designed to bring your proposal dreams to life. Each package is tailored to suit your style and preferences, ensuring that the moment is as personal and meaningful as possible.
                        </p>
                        <p className="text-lg sm:text-xl lg:text-2xl leading-relaxed text-[#bbbbbb] mt-6">
                            From the initial idea to the final "yes," we handle every detail with care and precision. Our talented team is dedicated to capturing each special moment while maintaining open communication with you throughout the entire planning and execution process. We work closely with you to ensure your vision becomes reality, ensuring every element aligns perfectly with your imagination and dreams.
                        </p>
                    </div>
                </div>
            </div>

            {/* Proposal Packages */}
            <div id="packages" className="section py-28 text-white">
                {/* Packages Title */}
                <div className='mx-auto text-center'>
                    <h2 className="text-3xl leading-tight font-playfair text-[#BBA085]">
                        Event Setup Packages
                    </h2>
                    <h2 className="text-4xl my-2 leading-tight font-playfair text-[#bbbbbb]">
                        Tailored for Your Perfect Celebration
                    </h2>
                </div>
                {/* Packages */}
                <div className='py-10 flex flex-col md:flex-row gap-8 justify-center items-center w-full'>
                    {/* Basic Package */}
                    <div className="bg-[#bba0853f] hover:bg-[#7564539d] transition-all w-full lg:w-1/3 flex flex-col justify-between items-center text-center relative p-6 gap-y-4 shadow-lg rounded-lg h-full">
                        <img src={branch} className="h-20 mb-4" alt="Decorative branch" />
                        <h3 className="text-4xl font-bold">Basic</h3>
                        <h4 className="text-3xl mb-4">$550</h4>
                        <ul className="py-4 text-lg gap-y-2 flex flex-col text-left list-disc">
                            <li>"Marry Me" Block Letters</li>
                            <li>Hundreds of Silk Rose Petals</li>
                            <li>Red Carpet</li>
                            <li>Candles in Various Sizes</li>
                            <li>Rose Stems</li>
                            <li>Complete Setup and Cleanup</li>
                        </ul>
                    </div>

                    {/* Deluxe Package */}
                    <div className="bg-[#bba0853f] hover:bg-[#7564539d] transition-all w-full lg:w-1/3 flex flex-col justify-between items-center text-center relative p-6 gap-y-4 shadow-lg rounded-lg h-full">
                        <img src={branch} className="h-20 mb-4" alt="Decorative branch" />
                        <h3 className="text-4xl font-bold">Deluxe</h3>
                        <h4 className="text-3xl mb-4">$750</h4>
                        <ul className="py-4 text-lg gap-y-2 flex flex-col text-left list-disc">
                            <li>Red Heart Arch</li>
                            <li>"Will You Marry Me" LED Sign</li>
                            <li>Hundreds of Silk Rose Petals</li>
                            <li>Red Carpet</li>
                            <li>Candles in Various Sizes</li>
                            <li>Rose Stems</li>
                            <li>Complete Setup and Cleanup</li>
                        </ul>
                    </div>
                    {/* Luxury Package */}
                    <div className="bg-[#bba0853f] hover:bg-[#7564539d] transition-all w-full lg:w-1/3 flex flex-col justify-between items-center text-center relative p-6 gap-y-4 shadow-lg rounded-lg h-full">
                        <img src={branch} className="h-20 mb-4" alt="Decorative branch" />
                        <h3 className="text-4xl font-bold">Luxury</h3>
                        <h4 className="text-3xl mb-4">$1200</h4>
                        <ul className="py-4 text-lg gap-y-2 flex flex-col text-left list-disc">
                            <li>Red Heart Arch</li>
                            <li>"Marry Me" Block Letters</li>
                            <li>"Will You Marry Me" LED Sign</li>
                            <li>Hundreds of Silk Rose Petals</li>
                            <li>Red Carpet</li>
                            <li>Candles in Various Sizes</li>
                            <li>Rose Stems</li>
                            <li>Bouquet of Roses</li>
                            <li>Fog Machine</li>
                            <li>Complete Setup and Cleanup</li>
                        </ul>
                    </div>
                </div>
                {/* premium experience Title */}
                <div className='mx-auto text-center pt-10'>
                    <h2 className="text-3xl leading-tight font-playfair text-[#BBA085]">
                        Premium Packages
                    </h2>
                    <h2 className="text-4xl my-2 leading-tight font-playfair text-[#bbbbbb]">
                        Our Best offerings with La Ciel
                    </h2>
                </div>
                {/* premium experience Packages */}
                <div className='pt-10 flex flex-col md:flex-row gap-8 justify-center items-center w-full'>
                    {/* Platinum  Package */}
                    <div className="bg-[#bba0853f] hover:bg-[#7564539d] transition-all w-full lg:w-1/3 flex flex-col justify-between items-center text-center relative p-6 gap-y-4 shadow-lg rounded-lg h-full">
                        <img src={branch} className="h-20 mb-4" alt="Decorative branch" />
                        <h3 className="text-4xl font-bold">Platinum </h3>
                        <h4 className="text-3xl mb-4">$2000</h4>
                        <div className="py-4 text-xl flex flex-col items-center text-left">
                            <h3>Luxury Event Setup Package</h3>
                            <BsPlus />
                            <h3>La Ciel Dinner Package</h3>
                            <ul className='list-disc text-lg'>
                                <li>
                                    Table Reservation
                                </li>
                                <li>
                                    Rose Petals
                                </li>
                                <li>
                                    Candles
                                </li>
                            </ul>
                        </div>
                        <img
                            className='h-20'
                            src='https://static.wixstatic.com/media/ee125c_422912a2233540138246f895713e3dfa~mv2.png/v1/fill/w_182,h_179,al_c,q_85,usm_0.66_1.00_0.01,enc_avif,quality_auto/La%20Ciel%20(1)_edited_edited_edited_edited_.png'
                        />
                    </div>

                    {/* Diamond  Package */}
                    <div className="bg-[#bba0853f] hover:bg-[#7564539d] transition-all w-full lg:w-1/3 flex flex-col justify-between items-center text-center relative p-6 gap-y-4 shadow-lg rounded-lg h-full">
                        <img src={branch} className="h-20 mb-4" alt="Decorative branch" />
                        <h3 className="text-4xl font-bold">Diamond </h3>
                        <h4 className="text-3xl mb-4">$2500</h4>
                        <div className="py-4 text-xl flex flex-col items-center text-left">
                            <h3>Luxury Event Setup Package</h3>
                            <BsPlus />
                            <h3>La Ciel Dinner Package</h3>
                            <ul className='list-disc text-lg'>
                                <li>
                                    Table Reservation
                                </li>
                                <li>
                                    Champagne
                                </li>
                                <li>
                                    Rose Petals
                                </li>
                                <li>
                                    Candles
                                </li>
                                <li>
                                    Baloons
                                </li>
                                <li>
                                    Heart Scatters on Table
                                </li>
                                <li>
                                    "Love" Sign
                                </li>
                                <li>
                                    Private Speaker
                                </li>
                                <li>
                                    Cake
                                </li>
                                <li>
                                    Glowing Sign
                                </li>
                            </ul>
                        </div>
                        <img
                            className='h-20'
                            src='https://static.wixstatic.com/media/ee125c_422912a2233540138246f895713e3dfa~mv2.png/v1/fill/w_182,h_179,al_c,q_85,usm_0.66_1.00_0.01,enc_avif,quality_auto/La%20Ciel%20(1)_edited_edited_edited_edited_.png'
                        />
                    </div>
                </div>
            </div>

            {/* Add-Ons */}
            <div id='addOns' className='py-28 bg-[#191930]'>
                <div className='section mx-auto text-center'>
                    <h2 className="text-3xl leading-tight font-playfair text-[#BBA085]">
                        Add-Ons
                    </h2>
                    <h2 className="text-4xl my-2 leading-tight font-playfair text-[#bbbbbb]">
                        Enhance Your Special Moment
                    </h2>
                </div>

                <div className="section flex flex-wrap justify-center items-center text-center font-bold gap-8 pt-16">
                    {[
                        { icon: <MdOutlinePhotoCameraFront />, text: "Photographer", price: '$250' },
                        { icon: <MdOutlineVideoCameraFront />, text: "Videographer", price: '$250' },
                        { icon: <FaCar />, text: "Limo Service", price: '$250' },
                        { icon: <IoSparklesOutline />, text: "Sparklers", price: '$200 each' },
                        { icon: <SiReasonstudios />, text: "Studio Time", price: 'Contact for Details' },
                        { icon: <PiChampagne />, text: "Champagne", price: 'Contact for Details' },
                    ].map(({ icon, text, price }, index) => (
                        <div key={index} className="flex flex-col items-center gap-y-4">
                            <div className="text-5xl sm:text-6xl text-centexOrange">{icon}</div>
                            <h3 className="text-[#BBA085] text-2xl sm:text-3xl font-semibold">{text}</h3>
                            <p className="text-xl sm:text-2xl font-light">{price}</p>
                        </div>
                    ))}
                </div>

            </div>

            <FAQSection faqs={[
                {
                    question: "How do we make it a surprise?",
                    answer: "Generally, these are the two methods we stick with to keep the surprise. Linked Here (on the surprise proposal page). Reach out to your photographer through email or text to coordinate and discuss specifics."
                },
                {
                    question: "What location should I propose at?",
                    answer: "Depending on the time of the year, your plans for the day, when sunrise and sunset take place, and which package you book, that will determine which location your photographer recommends. You can see all of our favourite locations here. Proposal Locations. If you have a preferred location, let your photographer know and they’ll recommend the best time of day. Your photographer will give you an 'X marks the spot' map for each specific location to help make sure you’re dropping a knee at the perfect spot."
                },
                {
                    question: "What do we mean by 'multiple locations'?",
                    answer: "Great question! Multiple locations refer to different backgrounds and compositions within the same spot. For example, at Tujak Lake, we’ll capture photos in various scenic areas around the lake, providing diverse backdrops without leaving the location. More locations mean more variety in your photos. If you have more questions, feel free to reach out to our team!"
                },
                {
                    question: "Do you help with choosing the perfect proposal ring?",
                    answer: "While we don’t provide rings, we can recommend expert jewelers who specialize in helping you pick the perfect engagement ring based on your partner's style and preferences. Feel free to reach out for guidance!"
                },
                {
                    question: "How can I make my proposal more personal?",
                    answer: "We love making proposals personal! Consider incorporating meaningful elements like favorite songs, locations with sentimental value, or a surprise element that tells your love story. We're happy to help brainstorm ideas that reflect your unique relationship."
                },
                {
                    question: "What if I want to propose in a more private setting?",
                    answer: "We can customize a proposal in a private setting, like at home, in a park, or at a secluded location. We work with you to create a perfect moment that feels intimate and personal, without sacrificing the magic of a proposal."
                },
                {
                    question: "How do you capture the proposal moment?",
                    answer: "We have experienced photographers who will be discreetly positioned nearby to capture the moment you get down on one knee. We take special care to ensure we get every shot while keeping your proposal a surprise."
                },
                {
                    question: "Can I include family or friends in the proposal?",
                    answer: "Absolutely! If you want to include close friends or family in your proposal, we can help coordinate their involvement in a way that’s seamless and surprise-friendly. Just let us know how you envision it, and we’ll make it happen."
                },
                {
                    question: "What happens after the proposal? Do you offer engagement photos?",
                    answer: "Yes! After your proposal, we can capture stunning engagement photos to celebrate the beginning of this new chapter. Whether it’s a quick mini-session or a full shoot, we’ll create photos you’ll cherish forever."
                },
                {
                    question: "Can you help with the proposal planning on short notice?",
                    answer: "We understand that life can get busy, so we offer last-minute proposal planning services. While it’s best to plan ahead, we’ll work with you to bring your proposal vision to life, even on a short timeline!"
                },
            ]} />

            <Contact />
        </div>
    );
}

export default Proposal;
