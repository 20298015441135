import React from 'react'
import { NavLink } from 'react-router-dom'

function NavigationLink({ link, title, className }) {
    return (
        <NavLink
            to={link}
            key={link}
            className={ "hover:text-[#BBA085] hover:scale-110 transition-all duration-200" + className}
        >
            {title}
        </NavLink>
    )
}

export default NavigationLink
