import React, { useEffect, useState } from 'react'
import NavigationLink from '../Components/Navigation/NavigationLink'
import MobileHeader from "../Sections/MobileHeader"
import NavigationLogo from '../Components/Navigation/NavigationLogo';
import NavigationDropdown from '../Components/Navigation/NavigationDropdown';
import { NavLink } from 'react-router-dom';
import NavigationButton from '../Components/Navigation/NavigationButton';
// import NavigationDropdown from '../Components/Navigation/NavigationDropdown';

function Header() {
  const [isScrolled, setIsScrolled] = useState(false);

  // scroll event listener to change header visibility
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      // Adjust the threshold value as needed
      const threshold = 300;

      if (scrollPosition > threshold) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <header className={`fixed w-full py-3 z-30 transition-colors duration-500 ease-in-out text-[#ECECEC] ${isScrolled ? 'bg-[#1f1f3b] text-[#ECECEC] shadow-lg border-gray-500 backdrop-blur-sm' : ''} `}>
      <div className='section flex justify-between'>
        <NavigationLogo className={"cursor-pointer"} />
        <nav className="hidden md:flex gap-x-8 text-xl items-center">
          <NavigationLink
            title={`Proposal`}
            link={"/Proposal/"}
          />
          <NavigationDropdown
            dropdownTitle={"Special Events"}
            dropdownLink={"/SpecialEvents"}
            links={[
              { name: 'Birthday', link: '/Birthday/' },
              { name: 'Anniversaries', link: '/Anniversaries/' },
              { name: 'Graduation', link: '/Graduation/' },
              { name: 'Baby Showers', link: '/BabyShowers/' },
              { name: 'Gender Reveals', link: '/GenderReveals/' },
              { name: 'Themed Parties', link: '/ThemedParties/' },
              { name: 'Pre-Wedding Events', link: '/Pre-WeddingEvents/' },
            ]}
          />
          <NavigationDropdown
            dropdownTitle={"About Us"}
            dropdownLink={"/#about"}
            links={[
              { name: 'Our Services', link: '/#services' },
              { name: 'Gallery', link: '/Gallery/' },
            ]}
          />
          <NavigationButton
            link={"/booking/"}
            title={"Book Now"}
          />
        </nav>
        <MobileHeader />
      </div>
    </header>
  )
}

export default Header
