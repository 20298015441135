import React from 'react';
import PageBanner from '../Sections/PageBanner';
import branch from "../media/branch.svg"
import useScrollToHash from '../utilities/useScrollToHash';
import { MdOutlineVideoCameraFront } from "react-icons/md";
import { MdOutlinePhotoCameraFront } from "react-icons/md";
import { IoSparklesOutline } from "react-icons/io5";
import { SiReasonstudios } from "react-icons/si";
import { PiChampagne } from "react-icons/pi";
import proposal2 from "../media/Proposal2.jpg"
import FAQSection from '../Sections/FAQSection';
import Contact from '../Sections/Contact';
import { Helmet } from "react-helmet";

function Graduation() {
    useScrollToHash();
    return (
        <div className="bg-[#2C2C54] text-[#bbbbbb]">
            <Helmet>
                <title>Nostalgic Moments | Calgary Graduation Planners</title>
                <meta name="description" content="Nostalgic Moments specializes in unforgettable Calgary graduation celebrations. From creative ideas to flawless execution, we help you create a memorable graduation experience." />
                <meta name="keywords" content="Calgary graduation planning, graduation ideas Calgary, memorable graduation celebrations, creative graduation planners, graduation services Calgary, graduation event planning, surprise graduation ideas, graduation packages Calgary, luxury graduation celebrations, graduation organizers, creative graduation planning, custom graduation design Calgary, graduation planners, celebratory event planning" />
            </Helmet>
            <PageBanner text={'A Graduation to Remember, Crafted with Care'}
                backgroundImage={"https://www.urbannexusstore.com/cdn/shop/articles/phd-graduation-party-ideas-celebrating-your-academic-triumph-230231_600x.jpg?v=1714979130"}
                links={[
                    { name: 'What We Do', link: '/Graduation/#whatWeDo' },
                    { name: 'Our Packages', link: '/Graduation/#packages' },
                    { name: 'AddOns', link: '/Graduation/#addOns' },
                    { name: 'FAQ', link: '/Graduation/#faq' },
                ]} />

            {/* What we do */}
            <div id="whatWeDo" className="bg-[#191930]">
                <div className='section text-center py-24 flex flex-col-reverse md:flex-row items-center md:text-left'>
                    {/* Image Section */}
                    <div className="md:w-1/3 px-6 mt-8 lg:mt-0 flex justify-center lg:justify-end">
                        <img
                            alt="celebration setup"
                            src={"https://i.pinimg.com/236x/3c/8a/63/3c8a63a1e87b6a1792cbe197cb152f35.jpg"}
                            className="w-full saturate-0 max-w-xs sm:max-w-sm md:max-w-md lg:max-w-xl rounded-lg shadow-lg object-cover"
                        />
                    </div>
                    {/* Text Section */}
                    <div className="md:w-2/3 px-6 lg:px-12 py-16">
                        <h2 className="text-3xl sm:text-4xl lg:text-5xl pb-6 lg:pb-12 leading-snug font-playfair text-[#BBA085]">
                            Celebrate Your Graduation with Unmatched Elegance and Joy
                        </h2>
                        <p className="text-lg sm:text-xl lg:text-2xl leading-relaxed text-[#bbbbbb]">
                            At <strong className="text-[#BBA085]">Nostalgic Moments</strong>, we understand the significance of your graduation day — a pivotal moment that marks the culmination of hard work and dedication. Our expert team is here to bring your vision to life with a personalized celebration designed to highlight your accomplishments and create lasting memories.
                        </p>
                        <p className="text-lg sm:text-xl lg:text-2xl leading-relaxed text-[#bbbbbb] mt-6">
                            From stunning backdrops to flawless execution, we offer tailored packages that capture the essence of your journey. Let us handle the details while you focus on enjoying every moment with friends and family. Together, we’ll make your graduation day unforgettable.
                        </p>
                    </div>

                </div>
            </div>

            {/* Proposal Packages */}
            <div id="packages" className="section py-28 text-white">
                {/* Packages Title */}
                <div className='mx-auto text-center'>
                    <h2 className="text-3xl leading-tight font-playfair text-[#BBA085]">
                        Packages
                    </h2>
                    <h2 className="text-4xl my-2 leading-tight font-playfair text-[#bbbbbb]">
                        Tailored for Your Perfect Celebration
                    </h2>
                </div>
                {/* Packages */}
                <div className='py-10 flex flex-col md:flex-row gap-8 justify-center items-center w-full'>
                    {/* Basic Package */}
                    <div className="bg-[#bba0853f] hover:bg-[#7564539d] transition-all w-full lg:w-1/3 flex flex-col justify-between items-center text-center relative p-6 gap-y-4 shadow-lg rounded-lg h-full">
                        <img src={branch} className="h-20 mb-4" alt="Decorative branch" />
                        <h3 className="text-4xl font-bold">Basic</h3>
                        <h4 className="text-3xl mb-4">$500</h4>
                        <div className="py-4 text-lg">
                            <p>Standard Big Heart Backdrop</p>
                            <p>Small Rose Petal Arrangement</p>
                            <p>Romantic Candle Display</p>
                        </div>
                    </div>

                    {/* Deluxe Package */}
                    <div className="bg-[#bba0853f] hover:bg-[#7564539d] transition-all w-full lg:w-1/3 flex flex-col justify-between items-center text-center relative p-6 gap-y-4 shadow-lg rounded-lg h-full">
                        <img src={branch} className="h-20 mb-4" alt="Decorative branch" />
                        <h3 className="text-4xl font-bold">Deluxe</h3>
                        <h4 className="text-3xl mb-4">$750</h4>
                        <div className="py-4 text-lg">
                            <p>Standard Big Heart Backdrop</p>
                            <p>Large Rose Petal Arrangement</p>
                            <p>Candle Display</p>
                            <p>Fresh Floral Accents</p>
                        </div>
                    </div>

                    {/* Luxury Package */}
                    <div className="bg-[#bba0853f] hover:bg-[#7564539d] transition-all w-full lg:w-1/3 flex flex-col justify-between items-center text-center relative p-6 gap-y-4 shadow-lg rounded-lg h-full">
                        <img src={branch} className="h-20 mb-4" alt="Decorative branch" />
                        <h3 className="text-4xl font-bold">Luxury</h3>
                        <h4 className="text-3xl mb-4">$1200</h4>
                        <div className="py-4 text-lg">
                            <p>Custom Floral Archway</p>
                            <p>Full Rose Petal Pathway</p>
                            <p>Premium Candle Arrangement</p>
                            <p>Decorative Lighting</p>
                            <p>Personalized Message Board</p>
                        </div>
                    </div>
                </div>
                {/* premium experience Title */}
                <div className='mx-auto text-center pt-10'>
                    <h2 className="text-3xl leading-tight font-playfair text-[#BBA085]">
                        Premium Packages
                    </h2>
                    <h2 className="text-4xl my-2 leading-tight font-playfair text-[#bbbbbb]">
                        Our Best offerings with La Ciel
                    </h2>
                </div>
                {/* premium experience Packages */}
                <div className='pt-10 flex flex-col md:flex-row gap-8 justify-center items-center w-full'>
                    {/* Platinum  Package */}
                    <div className="bg-[#bba0853f] hover:bg-[#7564539d] transition-all w-full lg:w-1/3 flex flex-col justify-between items-center text-center relative p-6 gap-y-4 shadow-lg rounded-lg h-full">
                        <img src={branch} className="h-20 mb-4" alt="Decorative branch" />
                        <h3 className="text-4xl font-bold">Platinum </h3>
                        <h4 className="text-3xl mb-4">$1750</h4>
                        <div className="py-4 text-lg">
                            <p>Luxury Package</p>
                            <p>La Ciel Dinner Package</p>
                        </div>
                    </div>

                    {/* Diamond  Package */}
                    <div className="bg-[#bba0853f] hover:bg-[#7564539d] transition-all w-full lg:w-1/3 flex flex-col justify-between items-center text-center relative p-6 gap-y-4 shadow-lg rounded-lg h-full">
                        <img src={branch} className="h-20 mb-4" alt="Decorative branch" />
                        <h3 className="text-4xl font-bold">Diamond </h3>
                        <h4 className="text-3xl mb-4">$2000</h4>
                        <div className="py-4 text-lg">
                            <p>Luxury Package</p>
                            <p>La Ciel Top Dinner Package</p>
                        </div>
                    </div>
                </div>
            </div>

            {/* Add-Ons */}
            <div id='addOns' className='py-28 bg-[#191930]'>
                <div className='section mx-auto text-center'>
                    <h2 className="text-3xl leading-tight font-playfair text-[#BBA085]">
                        Add-Ons
                    </h2>
                    <h2 className="text-4xl my-2 leading-tight font-playfair text-[#bbbbbb]">
                        Enhance Your Special Moment
                    </h2>
                </div>

                <div className="section flex flex-wrap justify-center items-center text-center font-bold gap-8 pt-16">
                    {[
                        { icon: <MdOutlinePhotoCameraFront />, text: "Photographer", price: '$250' },
                        { icon: <MdOutlineVideoCameraFront />, text: "Videographer", price: '$250' },
                        { icon: <IoSparklesOutline />, text: "Sparklers", price: '$200 each' },
                        { icon: <SiReasonstudios />, text: "Studio Time", price: '$200 each' },
                        { icon: <PiChampagne />, text: "Champagne", price: 'Contact for Details' },
                    ].map(({ icon, text, price }, index) => (
                        <div key={index} className="flex flex-col items-center gap-y-4 mx-4">
                            <div className="text-5xl sm:text-6xl text-centexOrange">{icon}</div>
                            <h3 className="text-[#BBA085] text-2xl sm:text-3xl font-semibold">{text}</h3>
                            <p className="text-xl sm:text-2xl font-bold">{price}</p>
                        </div>
                    ))}
                </div>

            </div>

            <FAQSection faqs={[
                {
                    question: "How do we make it a surprise?",
                    answer: "Generally, these are the two methods we stick with to keep the surprise. Linked Here (on the surprise proposal page). Reach out to your photographer through email or text to coordinate and discuss specifics."
                },
                {
                    question: "What location should I propose at?",
                    answer: "Depending on the time of the year, your plans for the day, when sunrise and sunset take place, and which package you book, that will determine which location your photographer recommends. You can see all of our favourite locations here. Proposal Locations. If you have a preferred location, let your photographer know and they’ll recommend the best time of day. Your photographer will give you an “X marks the spot” map for each specific location to help make sure you’re dropping a knee at the perfect spot."
                },
                {
                    question: "What do we mean by “multiple locations”?",
                    answer: "Great question! Multiple locations refer to different backgrounds and compositions within the same spot. For example, at Tujak Lake, we’ll capture photos in various scenic areas around the lake, providing diverse backdrops without leaving the location. More locations mean more variety in your photos. If you have more questions, feel free to reach out to our team!"
                },
            ]} />

            <Contact />
        </div>
    );
}

export default Graduation;

