import React from 'react';
import emailjs from 'emailjs-com';

export default function Contact() {
    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm('service_5vnbn9n', 'template_bry6jpg', e.target, 'mbr_CpZ4_INnTiNLn')
            .then(
                (result) => {
                    console.log('Email sent successfully!', result.text);
                    // Optionally, you can show a success message or redirect to a thank-you page.
                },
                (error) => {
                    console.log('Error sending email:', error.text);
                    // Optionally, you can show an error message.
                }
            );

        // Reset the form fields after submission (optional).
        e.target.reset();
    };

    return (
        <div id="Contact" className=" flex flex-col py-16 bg-cover bg-center bg-[#191930]">
            <div className="section md:flex gap-5">
                <div className='w-full text-center lg:text-left'>
                    <h2 className="text-5xl mb-3 leading-tight font-playfair text-[#BBA085]">
                        Contact Us
                    </h2>
                    <h2 className="text-2xl mb-12 leading-tight font-playfair text-[#bbbbbb]">
                        Ready to make your vision a reality?
                    </h2>
                    <p className="text-lg sm:text-xl lg:text-2xl max-w-4xl text-[#bbbbbb]">
                        Contact us today to discuss your ideas and start planning a memorable day.
                    </p>
                    <div className="mt-8 text-lg sm:text-xl text-[#bbbbbb]">
                        <p className="">[403]-909-6130</p>
                        <p className="">Nostalgic.Calgary@gmail.com</p>
                        <p className="">Calgary, Alberta, Camada</p>
                    </div>
                </div>
                <div className="text-lg text-slate-200 text-left w-full mx-auto">
                    <form
                        onSubmit={sendEmail}
                        className="space-y-6"
                        aria-label="Contact form"
                    >
                        <label className="block">
                            <span className="text-slate-400">Name</span>
                            <input
                                type="text"
                                name="from_name"
                                className="text-slate-100 p-3 w-full mt-1 rounded-lg focus:ring-2 focus:ring-slate-300 bg-[rgba(70,70,70,0.2)] shadow-inner"
                                placeholder="Enter your name"
                            />
                        </label>
                        <label className="block">
                            <span className="text-slate-400">Email Address</span>
                            <input
                                name="from_email"
                                type="email"
                                className="text-slate-100 p-3 w-full mt-1 rounded-lg focus:ring-2 focus:ring-slate-300 bg-[rgba(70,70,70,0.2)] shadow-inner"
                                placeholder="Enter your email"
                                required
                            />
                        </label>
                        <label className="block">
                            <span className="text-slate-400">Message</span>
                            <textarea
                                name="message"
                                className="p-3 text-slate-100 w-full mt-1 rounded-lg focus:ring-2 focus:ring-slate-300 bg-[rgba(70,70,70,0.2)] shadow-inner"
                                rows="4"
                                placeholder="Write your message here"
                            ></textarea>
                        </label>
                        <div className="pt-4">
                            <button
                                type="submit"
                                className="text-lg text-white transition-transform duration-200 hover:scale-105 bg-[#474787] px-5 py-3 rounded-lg shadow-md focus:bg-[rgba(139,139,139,0.3)] hover:shadow-xl w-full sm:w-auto sm:px-8"
                            >
                                Send Message
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

    );
};