import React from 'react'
import PageBanner from '../Sections/PageBanner'
import useScrollToHash from '../utilities/useScrollToHash';
import { Helmet } from 'react-helmet';

function Gallery() {
    useScrollToHash();
    return (
        <div className="bg-[#2C2C54] text-[#bbbbbb]">
            <Helmet>
                <title>Nostalgic Moments Gallery | Calgary Event Planning</title>
                <meta name="description" content="Browse through the Nostalgic Moments gallery to see how we bring unforgettable events to life. From creative proposals to special celebrations, explore our expertly planned moments." />
                <meta name="keywords" content="Calgary event planning, proposal planning gallery, graduation celebration gallery, event planning portfolio, Calgary special events, creative event planning, luxury event planning Calgary, memorable event moments, wedding proposal planning, special celebrations Calgary, event design gallery, event planners Calgary, proposal ideas Calgary, custom event planning, Calgary event organizers" />
            </Helmet>

            <PageBanner text={''}
                backgroundImage={"https://storage.googleapis.com/bookfocal-production/images/c63a16b2fabd4d338b70826835f6d268.jpg"}
                links={[
                    { name: 'All', link: '/portfolio/#Proposals' },
                    { name: 'Proposals', link: '/portfolio/#Proposals' },
                    { name: 'Baby Showers', link: '/portfolio/#packages' },
                    { name: 'Graduation', link: '/portfolio/#addOns' },
                    { name: 'Special Events', link: '/portfolio/#faq' },
                ]} />
            {/* Portfolio Section */}
            <div id='portfolio' className=" flex flex-col py-28 bg-cover bg-center text-center bg-[#191930]">
                <div className="section px-8 flex flex-col gap-y-5">
                    {/* Image Thumbnails */}
                    <div className="columns-2 md:columns-3 gap-4 mt-8 space-y-4">
                        <img
                            src="https://paisleyphotos.ca/wp-content/uploads/2014/10/2014-10-01_0006.jpg"
                            alt="Event 1"
                            className="w-full h-auto rounded-lg shadow-md hover:scale-105 transform transition-transform duration-300 brightness-50 hover:brightness-75"
                        />
                        <img
                            src="https://images.squarespace-cdn.com/content/v1/63c03c48d4f725460eaf4b2e/64f7ce42-15c7-4f15-b358-d173ddd78d2b/Surprise+Proposal-Extra-105.jpg"
                            alt="Event 2"
                            className="w-full h-auto rounded-lg shadow-md hover:scale-105 transform transition-transform duration-300 brightness-50 hover:brightness-75"
                        />
                        <img
                            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSQavISFSFTsHu_Gt-1h5ZF03jLGVajuZv1gA&s"
                            alt="Event 3"
                            className="w-full h-auto rounded-lg shadow-md hover:scale-105 transform transition-transform duration-300 brightness-50 hover:brightness-75"
                        />
                        <img
                            src="https://static.wixstatic.com/media/cc19ea_fa9dcb6d51ca483fbcc6cd8ac0dec162~mv2.jpg/v1/crop/x_0,y_401,w_1537,h_1504/fill/w_520,h_508,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/1F4337F2-502F-4735-8308-7D4CEB4C5CCE_JPG.jpg"
                            alt="Event 4"
                            className="w-full h-auto rounded-lg shadow-md hover:scale-105 transform transition-transform duration-300 brightness-50 hover:brightness-75"
                        />
                        <img
                            src="https://www.ashleydaphnephotography.com/wp-content/uploads/2020/03/Ashley-Daphne-Photography-Calgary-Couple-Wedding-Photographer-Engagement-Photos-Proposal-Photos_0031.jpg"
                            alt="Event 5"
                            className="w-full h-auto rounded-lg shadow-md hover:scale-105 transform transition-transform duration-300 brightness-50 hover:brightness-75"
                        />
                        <img
                            src="https://paisleyphotos.ca/wp-content/uploads/2014/10/2014-10-01_0004.jpg"
                            alt="Event 6"
                            className="w-full h-auto rounded-lg shadow-md hover:scale-105 transform transition-transform duration-300 brightness-50 hover:brightness-75"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Gallery
