import React from 'react';
import { NavLink } from 'react-router-dom'; // Import NavLink

function ServiceCard({ img, title, disc, offerings, link }) {
  return (
    <div className="group relative overflow-hidden transition-all duration-400 transform shadow-md md:h-[75vh] rounded-lg">
      {/* Image */}
      <img
        loading="lazy"
        className="object-cover w-full h-full rounded"
        src={img}
        alt={title}
        decoding="async"
      />

      {/* Overlay for Title on Image */}
      <div className="sm:opacity-100 absolute inset-0 flex flex-col justify-center items-center backdrop-brightness-[30%]">
        <h1 className="mb-10 text-3xl lg:text-4xl text-[#BBA085] font-semibold z-10 hidden md:flex">
          {title}
        </h1>
        <p className='text-lg lg:text-xl px-10 font-light text-[#bbbbbb] z-10 hidden md:flex'>
          {disc}
        </p>
        <div className="mb-4 text-3xl lg:text-4xl text-white font-semibold z-10 md:hidden text-center">
          <div>
            <h1>{title}</h1>
          </div>
          <NavLink to={link} className="w-full flex items-center justify-center shadow-md h-20 bg-[rgba(101,90,131,0.64)] backdrop-blur-lg text-xl font-light absolute bottom-0 left-0">
            Learn More
          </NavLink>
        </div>
      </div>

      {/* Details Overlay */}
      <div className="absolute inset-0 flex-col justify-between items-center px-5 py-20 opacity-0 hover:bg-opacity-100 hover:opacity-100 text-center z-10 hidden md:flex backdrop-blur backdrop-brightness-[0.3] duration-700 transition-all">
        <ul className="text-[#bbbbbb] w-full align-middle items-center">
          {offerings.map((offering, index) => (
            <React.Fragment key={index}>
              <li className="">
                <h2 className=" text-lg font-light py-3">{offering}</h2>
              </li>
              {/* {index !== offerings.length - 1 && (
                <div className="w-[70%] mx-auto h-[1px] bg-gradient-to-r from-[rgb(255,255,255,0)] via-[rgb(255,255,255)] to-[rgba(255,255,255,0)] rounded-full"></div>
              )} */}
            </React.Fragment>
          ))}
        </ul>
        {/* Replace anchor tag with NavLink */}
        <NavLink to={link} className="bg-[#2C2C54] hover:bg-[#474787] text-white lg:text-lg font-medium py-2 px-20 transition-all backdrop-blur-md shadow-lg hover:scale-105 rounded-lg">
          <h1>Learn More</h1>
        </NavLink>
      </div>
    </div>
  );
}

export default ServiceCard;
