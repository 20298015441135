import React from 'react';
import PageBanner from '../Sections/PageBanner';
import branch from "../media/branch.svg"
import useScrollToHash from '../utilities/useScrollToHash';
import { MdOutlineVideoCameraFront } from "react-icons/md";
import { MdOutlinePhotoCameraFront } from "react-icons/md";
import { IoSparklesOutline } from "react-icons/io5";
import { SiReasonstudios } from "react-icons/si";
import { PiChampagne } from "react-icons/pi";
import proposal2 from "../media/Proposal2.jpg"
import FAQSection from '../Sections/FAQSection';
import Contact from '../Sections/Contact';
import EventsCard from '../Components/EventsCard';
import { Helmet } from "react-helmet";

function SpecialEvents() {
    useScrollToHash();
    return (
        <div className="bg-[#e2e2e2] text-[#bbbbbb]">
            <Helmet>
                <title>Nostalgic Moments | Calgary Special Events</title>
                <meta name="description" content="Nostalgic Moments creates unforgettable special events in Calgary, including proposals, weddings, birthdays, anniversaries, baby showers, themed parties, and more. Let us make your moments magical." />
                <meta
                    name="keywords"
                    content="Calgary special events, event planning Calgary, weddings Calgary, birthday parties Calgary, anniversary celebrations Calgary, baby showers Calgary, gender reveal parties Calgary, themed parties Calgary, proposal planning Calgary, luxury events Calgary, engagement events Calgary, pre-wedding planning Calgary, milestone celebrations Calgary, Calgary event decor, unique events Calgary, private party planners Calgary, holiday events Calgary, gala events Calgary, Calgary graduation parties, Calgary intimate gatherings, professional event planning Calgary, custom event coordination Calgary, creative event planners Calgary, unforgettable Calgary events, Calgary cultural celebrations, backyard parties Calgary, corporate event planning Calgary, seasonal events Calgary, surprise parties Calgary, charity events Calgary, Calgary community events, bespoke event planning Calgary"
                />
            </Helmet>

            <PageBanner text={'Special Events'}
                backgroundImage={"https://alpha.creativecirclecdn.com/environment/original/20240812-124851-c69-sky-full-of-fireworks.jpg"}
                links={[
                    { name: 'What We Do', link: '/SpecialEvents/#whatWeDo' },
                    { name: 'What We Offer', link: '/SpecialEvents/#WhatWeOffer' },
                    { name: 'Contact Us', link: '/SpecialEvents/#Contact' },
                ]} />

            {/* What we do */}
            <div id="whatWeDo" className="bg-[#bbbbbb]">
                <div className='section text-center p-24 md:flex-row items-center'>
                    <h2 className="text-3xl sm:text-4xl lg:text-5xl pb-6 lg:pb-12 leading-snug font-playfair text-[#2C2C54]">
                        Turning Special Occasions Into Nostalgic Memories
                    </h2>
                    <p className="text-lg sm:text-xl lg:text-2xl leading-relaxed text-[#191930] md:px-20">
                        Every special moment deserves a celebration that truly reflects its significance. Whether you're marking a milestone birthday, celebrating years of love, or hosting a baby shower, our team is dedicated to creating an unforgettable experience tailored to your vision. We take care of every detail, blending creativity and precision to turn your dreams into a reality.
                    </p>
                    {/* <p className="text-lg sm:text-xl lg:text-2xl leading-relaxed text-[#bbbbbb] mt-6">
                            From personalized planning to flawless execution, we ensure every detail is handled with care and precision. With our team by your side, you’ll enjoy heartfelt service that transforms your moments into cherished memories.
                        </p> */}
                </div>

            </div>

            {/* Events */}
            <div id='WhatWeOffer' className='section py-24 text-center'>
                <h2 className="text-2xl md:text-3xl leading-tight font-playfair text-[#BBA085]">
                    Events
                </h2>
                <h2 className="text-4xl md:text-5xl my-2 leading-tight font-playfair text-[#2C2C54]">
                    What We Offer
                </h2>
                <div className='grid grid-cols-2 md:grid-cols-3 py-14 gap-x-6 md:gap-x-24 gap-y-8 justify-center'>
                    <EventsCard
                        Img={"https://borntoparty.in/img/what-we-do/birthday-event-planner.jpg"}
                        Title={"Birthday"}
                        Link={"/Birthday/"}
                    />
                    <EventsCard
                        Img={"https://thepeacockevents.com/wp-content/uploads/2024/05/f77.jpeg"}
                        Title={"Anniversaries"}
                        Link={"/Anniversaries/"} />
                    <EventsCard
                        Img={"https://m.media-amazon.com/images/I/71LmvkK66gL._AC_SL1500_.jpg"}
                        Title={"Graduation Parties"}
                        Link={"/Graduations/"}
                    />
                    <EventsCard
                        Img={"https://cdn.prod.website-files.com/5e96f6c1d119288a704fa082/65e1cc4ac9e730479a40c420_gold_balloon_arch_kit.webp"}
                        Title={"Baby Showers"}
                        Link={"/BabyShowers/"}
                    />
                    <EventsCard
                        Img={"https://rahmeevents.com/cdn/shop/files/B4CA76E9-781F-4A6B-92E6-DC39B040D0B0.jpg?v=1719590280&width=1946"}
                        Title={"Engagement Parties"}
                        Link={"/Engagement Parties/"}
                    />
                    <EventsCard
                        Img={"https://onlinepartysupplies.com.au/cdn/shop/files/pastel-blue-pink-gold-butterfly-retro-apricot-latex-balloon-garland-diy-kit-baby-shower-birthday-party-centrepiece-decorations_1600x.jpg?v=1730249401"}
                        Title={"Gender Reveals"}
                        Link={"/GenderReveals/"}
                    />
                    <EventsCard
                        Img={"https://cdn.shopify.com/s/files/1/0434/6033/files/Cars_Party_Photo_1_1024x1024.png?v=1720854997"}
                        Title={"Themed Parties"}
                        Link={"/ThemedParties/"}
                    />
                    <EventsCard
                        Img={"https://weddingconnexion.com/tropical-destination-wedding-blog/wp-content/uploads/2021/04/whie-and-rose-gold-glamorous-bridal-shower-decor.jpg"}
                        Title={"Pre-Wedding Events"}
                        Link={"/PreWedding/"}
                    />
                    <EventsCard
                        Img={"https://s7.orientaltrading.com/is/image/OrientalTrading/FXBanner_808/120-pcs-black-and-gold-balloon-garland-kit-with-confetti-for-party-and-event-decor~14562872.jpg"}
                        Title={"Special Requests"}
                        Link={"/SpecialEvents/#Contact"}
                    />
                </div>
            </div>

            {/* <FAQSection faqs={[
                {
                    question: "How do we make it a surprise?",
                    answer: "Generally, these are the two methods we stick with to keep the surprise. Linked Here (on the surprise proposal page). Reach out to your photographer through email or text to coordinate and discuss specifics."
                },
                {
                    question: "What location should I propose at?",
                    answer: "Depending on the time of the year, your plans for the day, when sunrise and sunset take place, and which package you book, that will determine which location your photographer recommends. You can see all of our favourite locations here. Proposal Locations. If you have a preferred location, let your photographer know and they’ll recommend the best time of day. Your photographer will give you an “X marks the spot” map for each specific location to help make sure you’re dropping a knee at the perfect spot."
                },
                {
                    question: "What do we mean by “multiple locations”?",
                    answer: "Great question! Multiple locations refer to different backgrounds and compositions within the same spot. For example, at Tujak Lake, we’ll capture photos in various scenic areas around the lake, providing diverse backdrops without leaving the location. More locations mean more variety in your photos. If you have more questions, feel free to reach out to our team!"
                },
            ]} /> */}

            <Contact />
        </div>
    );
}

export default SpecialEvents
