import ScrollToTopButton from './Components/Navigation/ScrollToTopButton';
import Gallery from './Pages/Gallery';
import Graduation from './Pages/Graduation';
import Home from './Pages/Home';
import SpecialEvents from './Pages/SpecialEvents';
import Footer from './Sections/Footer';
import Header from './Sections/Header';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Proposal from './Pages/Proposal';
import Birthday from './Pages/Birthday';
import Booking from './Pages/Booking';

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/SpecialEvents/" element={<SpecialEvents/>} />
        <Route path="/Gallery/" element={<Gallery/>} />
        <Route path="/booking/" element={<Booking/>} />
        {/* Services */}
        <Route path="/Proposal/" element={<Proposal/>} />
        <Route path="/Birthday/" element={<Birthday/>} />
        <Route path="/Graduation/" element={<Graduation/>} />
        <Route path="/Anniversaries/" element={<Graduation/>} />
        <Route path="/BabyShowers/" element={<Graduation/>} />
        <Route path="/GenderReveals/" element={<Graduation/>} />
        <Route path="/ThemedParties/" element={<Graduation/>} />
        <Route path="/Pre-WeddingEvents/" element={<Graduation/>} />
      </Routes>
      <ScrollToTopButton />
      <Footer/>
    </Router>
  );
}

export default App;
