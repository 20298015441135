import React from 'react'
import { NavLink } from 'react-router-dom'

function EventsCard({ Img, Title, Link }) {
    return (
        <div className="bg-[#bbbbbb] text-center relative overflow-hidden transition duration-200 transform rounded shadow-xl hover:-translate-y-2 hover:shadow-2xl cursor-default">
            <img
                loading="lazy"
                className="object-cover object-bottom w-full h-56 md:h-64 xl:h-80 brightness-75"
                src={Img}
                alt=""
                decoding="async"
                sizes="(max-width: 140px) 100vw, (max-width: 168px) 50vw, 33.3vw"
            />
            <div className='pb-2 pt-2 flex flex-col gap-y-5 px-3 text-center'>
                <h2 className="text-2xl md:text-3xl text-[#2C2C54]">
                    {Title}
                </h2>
                <NavLink
                    to={Link}
                    className="mx-auto w-fit rounded-full bg-[#474787be] hover:bg-[#2C2C54] text-white py-2 px-5 md:px-10 transition-all shadow-lg"
                >
                    Learn More
                </NavLink>
            </div>
        </div>
    )
}

export default EventsCard
