// FAQSection.js
import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const FAQSection = ({ faqs }) => {
    const [activeIndex, setActiveIndex] = useState(null);

    const toggleFAQ = (index) => {
        setActiveIndex(index === activeIndex ? null : index);
    };

    return (
        <div id='faq' className='py-28'>
            <div className="section faq-section max-w-screen-md mx-auto ">
                <div className='mx-auto text-center pb-24'>
                    <h2 className="text-3xl leading-tight font-playfair text-[#BBA085]">
                        Frequently Asked Questions
                    </h2>
                    <h2 className="text-4xl my-2 leading-tight font-playfair text-[#bbbbbb]">
                        Everything You Need to Know About Our Services
                    </h2>
                </div>

                <div className="space-y-4">
                    {faqs.map((faq, index) => (
                        <div key={index} className="bg-[#bba0853f] hover:bg-[#7564539d] p-4 rounded-lg shadow-md">
                            <button
                                onClick={() => toggleFAQ(index)}
                                className="w-full text-left text-lg font-semibold text-white"
                            >
                                {faq.question}
                            </button>
                            <AnimatePresence>
                                {activeIndex === index && (
                                    <motion.div
                                        initial={{ opacity: 0, height: 0 }}
                                        animate={{ opacity: 1, height: 'auto' }}
                                        exit={{ opacity: 0, height: 0 }}
                                        transition={{ duration: 0.3 }}
                                        className="overflow-hidden text-[#bbbbbb] mt-2"
                                    >
                                        <p>{faq.answer}</p>
                                    </motion.div>
                                )}
                            </AnimatePresence>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default FAQSection;
